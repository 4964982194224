import React, { useContext } from 'react'
import { connect } from 'react-redux'
import TrendingDestinations from '../../components/TrendingDestinations/TrendingDestinations'
import Search from '../../components/Search/Search'
import Pane from 'components/Common/Pane'
import { isLoggedIn } from 'selectors/accountSelectors'
import HeroOfferCarouselSection from '../../components/OfferCarousels/HeroOfferCarouselSection'
import HotelOfferCarousel from '../../components/OfferCarousels/HotelOfferCarousel'
import TourOfferCarousel from '../../components/OfferCarousels/TourOfferCarousel'
import ValuePropositionBanner from '../../components/ValuePropositionBanner/ValuePropositionBanner'
import MarketingCarousel from '../../components/MarketingCarousel/MarketingCarousel'
import BlogCarousel from '../../components/Blog/BlogCarousel'
import BrowseOfferTypes from 'home/components/BrowseOfferTypes/BrowseOfferTypes'
import SmartExperienceOfferCarousel from 'home/components/OfferCarousels/ExperienceOfferCarousel/SmartExperienceOfferCarousel'
import GeoContext from 'contexts/geoContext'
import UltraLuxOfferCarousel from 'home/components/OfferCarousels/UltraLuxHotelOfferCarousel'
import config from 'constants/config'
import TopPicksForMeCarousel from 'home/components/OfferCarousels/TopPicksForMeCarousel'
import PromptCardSelector from 'components/Common/StickyPromptCard/PromptCardSelector'
import { OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'
import HomepageHighIntentOffers from 'home/components/OfferCarousels/HomepageHighIntentOffers'
import useHomepageAnalytics from 'home/pages/HomePage/useHomepageAnalytics'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import { GlobalSearchTrackingProvider } from 'contexts/GlobalSearch/GlobalSearchTracking'
import HomepageFlightDeals from 'home/components/FlightDeals/HomepageFlightDeals'
import AnalyticsPageContext, { AnalyticsPage } from 'contexts/Analytics/analyticsPageContext'
import ErrorBoundary from 'components/Common/ErrorBoundary/ErrorBoundary'
import TrustIndicatorSection from 'home/components/TrustIndicatorSection/TrustIndicatorSection'
import CruiseOfferCarousel from 'home/components/OfferCarousels/CruiseOfferCarousel'
import { isStandaloneCruiseEnabled } from 'selectors/cruiseOfferSelectors'
import FrontPageUSPComponent from 'components/Pages/FrontPage/USP/FrontPageUSPComponent'
import Divider from 'components/Luxkit/Divider'
import LayoutContainer from 'components/Common/LayoutContainer'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Trip from 'home/components/Trip'
import { InView } from 'react-intersection-observer'
import SupportTile from 'components/Common/SupportTile/SupportTile'
import { HOMEPAGE_FLIGHT_DEALS_REGIONS, NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'
import Error404PageWithOffers from 'components/Pages/Error404PageWithOffers/Error404PageWithOffers'
import { OptimizelyExperiments } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import RedirectWithStatus from 'components/Common/RedirectWithStatus'
import useQueryParams from 'hooks/useQueryParams'

const HOMEPAGE_VERSION = 'homepage'

const StyledLayoutContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
`

// container created outside of component as padding is different to the destination landing page
const StyledHeroOfferCarousel = styled(HeroOfferCarouselSection)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${mediaQueryUp.desktop} {
    padding-bottom: ${rem(100)};
  }
`

interface MappedProps {
  isLoggedIn: boolean;
  isStandaloneCruiseEnabled: boolean;
}

function HomePage(props: MappedProps) {
  const { isLoggedIn, isStandaloneCruiseEnabled } = props
  const query = useQueryParams()
  const { currentRegionCode, currentCurrency } = useContext(GeoContext)
  const showSupportContainer = !NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode)
  const showFlightDeals = HOMEPAGE_FLIGHT_DEALS_REGIONS.includes(currentRegionCode)

  const utmSource = query.get('utm_source')
  const utmMedium = query.get('utm_medium')
  const utmTerm = query.get('utm_term')

  const useLandingPageExperiment: boolean = !!useOptimizelyExperiment(OptimizelyExperiments.romanticLandingPage, utmSource === 'google' && utmMedium === 'cpc' && (utmTerm?.includes('romantic') || utmTerm?.includes('family')))

  const {
    onTourListEvent,
    onCruiseListEvent,
    onHotelListEvent,
    onBestValueHotelListEvent,
    onUltraLuxListEvent,
    onHeroListEvent,
    onSearchEvent,
    onTrendingDestinationsListEvent,
    onExperienceListEvent,
    onTPFMListEvent,
    onTripOfferListEvent,
    onFlightListEvent,
  } = useHomepageAnalytics(HOMEPAGE_VERSION, currentCurrency)

  if (useLandingPageExperiment) {
    if (utmTerm?.toLowerCase().includes('romantic')) {
      return <RedirectWithStatus withSearchAndHash={false} code={302} to={`${currentRegionCode.toLowerCase()}/destination/romantic?${query.toString()}&holidayTypes=Romantic`} />
    } else if (utmTerm?.toLowerCase().includes('family')) {
      return <RedirectWithStatus withSearchAndHash={false} code={302} to={`${currentRegionCode.toLowerCase()}/destination/family-friendly?${query.toString()}&holidayTypes=Family+Friendly`} />
    }
  }

  return (
    <AnalyticsPageContext.Provider value={AnalyticsPage.homepage}>
      <ErrorBoundary fallback={() => <Error404PageWithOffers />}>
        <Pane>
          <ErrorBoundary fallback={null}>
            <PromptCardSelector tripReengagement carHire />
          </ErrorBoundary>
          <OfferListEventsProvider onListEvent={onHeroListEvent}>
            <StyledHeroOfferCarousel />
          </OfferListEventsProvider>
          <GlobalSearchTrackingProvider onEvent={onSearchEvent}>
            <Search />
          </GlobalSearchTrackingProvider>
          {!isLoggedIn && <StyledLayoutContainer>
            <VerticalSpacer gap={40}>
              <FrontPageUSPComponent />
              <Divider kind="primary" />
            </VerticalSpacer>
          </StyledLayoutContainer>}
          {isLoggedIn && config.TRIP_PLANNER_ENABLE_HOMEPAGE_MODULE && (
            <OfferListEventsProvider onListEvent={onTripOfferListEvent}>
              <Trip />
            </OfferListEventsProvider>
          )}
          <OfferListEventsProvider onListEvent={onTrendingDestinationsListEvent}>
            <TrendingDestinations />
          </OfferListEventsProvider>
          {!isLoggedIn && <LayoutContainer>
            <VerticalSpacer gap={40}>
              <Divider kind="primary" />
              <TrustIndicatorSection />
              <Divider kind="primary" />
            </VerticalSpacer>
          </LayoutContainer>}
          <HomepageHighIntentOffers />
          <OfferListEventsProvider onListEvent={onTPFMListEvent}>
            <TopPicksForMeCarousel onHotelListEvent={onHotelListEvent}/>
          </OfferListEventsProvider>
          <OfferListEventsProvider onListEvent={onTourListEvent}>
            <TourOfferCarousel />
          </OfferListEventsProvider>
          <OfferListEventsProvider onListEvent={onBestValueHotelListEvent}>
            <HotelOfferCarousel sortBy="value" />
          </OfferListEventsProvider>
          {isStandaloneCruiseEnabled && (
            <OfferListEventsProvider onListEvent={onCruiseListEvent}>
              <CruiseOfferCarousel />
            </OfferListEventsProvider>
          )}
          <MarketingCarousel />
          {isLoggedIn && <OfferListEventsProvider onListEvent={onHotelListEvent}>
            <HotelOfferCarousel />
          </OfferListEventsProvider>}
          {showSupportContainer && <LayoutContainer>
            <SupportTile />
          </LayoutContainer>}
          {config.ULTRALUX_ENABLED && (
            <OfferListEventsProvider onListEvent={onUltraLuxListEvent}>
              <UltraLuxOfferCarousel />
            </OfferListEventsProvider>
          )}
          <OfferListEventsProvider onListEvent={onExperienceListEvent}>
            <SmartExperienceOfferCarousel />
          </OfferListEventsProvider>
          {showFlightDeals &&
            <OfferListEventsProvider onListEvent={onFlightListEvent}>
              <InView rootMargin="100px 0px 0px 0px" triggerOnce>
                <HomepageFlightDeals />
              </InView>
            </OfferListEventsProvider>
              }
          <ValuePropositionBanner />
          <BlogCarousel />
          <BrowseOfferTypes />
        </Pane>
      </ErrorBoundary>
    </AnalyticsPageContext.Provider>
  )
}

function mapStateToProps(state: App.State): MappedProps {
  return {
    isLoggedIn: isLoggedIn(state),
    isStandaloneCruiseEnabled: isStandaloneCruiseEnabled(state),
  }
}

export default connect(mapStateToProps)(HomePage)
