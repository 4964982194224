import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineExchangeAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m21.71 9.29-4-4a1.0037 1.0037 0 0 0-.71-.294 1.0041 1.0041 0 0 0-.71 1.714L18.59 9H7a1 1 0 0 0 0 2h14a1.0005 1.0005 0 0 0 .92-.62.9996.9996 0 0 0-.21-1.09ZM17 13H3a1.0003 1.0003 0 0 0-.92.62 1 1 0 0 0 .21 1.09l4 4a1.001 1.001 0 0 0 .71.2958.9994.9994 0 0 0 .71-.2958 1.001 1.001 0 0 0 .2958-.71 1.0003 1.0003 0 0 0-.2958-.71L5.41 15H17a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineExchangeAltIcon
