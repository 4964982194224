import React, { useCallback, useContext, useMemo } from 'react'
import invariant from 'tiny-invariant'

import CruiseV2SavedPriceDetails from './CruiseV2SavedPriceDetails'

import OfferListEventsContext, {
  OfferListEvents,
} from 'components/OfferList/OfferListEventsContext'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import { useCruise } from 'hooks/apiQuery/cruise'
import useAwaitExistence from 'hooks/useAwaitExistence'
import { useRegionTimeFormat } from 'hooks/useRegionTimeFormat'
import noop from 'lib/function/noop'
import BookmarkLocation from 'tripPlanner/components/Bookmark/BookmarkCardNew/BookmarkLocation'
import MiniSavedOfferCard from 'tripPlanner/components/SavedOffers/MiniSavedOfferCard'
import { CruiseItem, SavedCruiseData } from 'tripPlanner/types/tripItem'
import {
  buildMyEscapesOrderLink,
  formatDatesAndDays,
  isLeBookmark,
} from 'tripPlanner/utils'
import { buildProductLink } from 'tripPlanner/utils/bookmark/restoreCart'

function isTourSavedData(
  data: SavedCruiseData | undefined,
): data is Exclude<SavedCruiseData, { offerType: 'cruise' }> {
  return !!data && data.offerType !== 'cruise'
}

interface Props {
  item: CruiseItem
  onClick?: () => void
  position?: number
}

export default function CruiseV2ItemCard({
  item,
  onClick = noop,
  position,
}: Props) {
  const timeFormat = useRegionTimeFormat()

  const savedItemData = item.savedItemData

  invariant(
    !isTourSavedData(savedItemData),
    'CruiseV2ItemCard can only be used for cruise V2 offer',
  )

  const timeDescription: string | undefined = formatDatesAndDays(
    item,
    timeFormat,
  )

  const { cruise } = useCruise(item.code)

  let tileLink: string | undefined
  if (
    item.sourceType === 'LE_Cruise' &&
    item.isBooked &&
    item.confirmationCode
  ) {
    tileLink = buildMyEscapesOrderLink(item)
  } else if (isLeBookmark(item)) {
    tileLink = buildProductLink(item)
  }

  const isOfferAvailable = savedItemData ?
      !savedItemData.isOfferSoldOut && !!savedItemData.price :
    true
  const imageId = savedItemData ? savedItemData.imageId : cruise?.images[0]?.id

  const priceAreaContent = useMemo(() => {
    if (savedItemData && savedItemData.offerType === 'cruise') {
      const { price, currencyCode, cabinCategory } = savedItemData

      return (
        <CruiseV2SavedPriceDetails
          price={price}
          currencyCode={currencyCode}
          cabinCategory={cabinCategory}
          isOfferAvailable={isOfferAvailable}
        />
      )
    }
    return null
  }, [isOfferAvailable, savedItemData])

  const locationDescription = useMemo(
    () => (
      <BookmarkLocation
        variant="single"
        startLocation={item.startPlace.name}
        showIcon={false}
      />
    ),
    [item.startPlace.name],
  )

  // A promise that resolves when the offer is available, so we can delay
  // firing any impression/click events until we have the necessary data.
  // Not needed for the other cards, because they all show a loading state
  // before the offer is loaded. Whereas for this card, the trip item already
  // has all the data it needs to render.
  const awaitOffer = useAwaitExistence(cruise)

  const fireOfferListEvent = useContext(OfferListEventsContext)

  const onImpression = useCallback(() => {
    awaitOffer.then((offer) =>
      fireOfferListEvent(OfferListEvents.impression, {
        offer,
        index: position,
        position,
        key: EventDataKey.ProductImpression,
      }),
    )
  }, [fireOfferListEvent, awaitOffer, position])

  const onClickCard = useCallback(() => {
    onClick()
    awaitOffer.then((offer) =>
      fireOfferListEvent(OfferListEvents.productClick, {
        offer,
        index: position,
        position,
        key: EventDataKey.ProductClick,
      }),
    )
  }, [onClick, fireOfferListEvent, awaitOffer, position])

  return (
    <MiniSavedOfferCard
      to={tileLink}
      onClick={onClickCard}
      onImpression={onImpression}
      item={item}
      name={item.name}
      imgId={imageId}
      locationDescription={locationDescription}
      descriptors={[timeDescription]}
      priceAreaContent={priceAreaContent}
      isSoldOut={!isOfferAvailable}
    />
  )
}
