import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import ResponsiveImage from 'components/Common/ResponsiveImage'

const BaseContainer = styled.div`
  position: relative;
  align-self: stretch;
  width: ${rem(100)};
  flex-shrink: 0;

  @media print {
    display: none;
  }
`

const Image = styled.img`
  position: absolute;
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
`

interface Props {
  /** URL of external image */
  imgSrc?: string
  /** ID of image from svc-image */
  imgId?: string
}

export function ItemImageContainer({ imgId, imgSrc }: Props) {
  let imageElement: React.ReactNode
  if (imgId) {
    imageElement = (
      <ResponsiveImage
        fit="center"
        id={imgId}
        mobileAspectRatio="2:3"
        mobileWidth={rem(100)}
      />
    )
  } else if (imgSrc) {
    imageElement = <Image src={imgSrc} />
  } else {
    return null
  }

  return <BaseContainer>{imageElement}</BaseContainer>
}
