import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { fareTypes, fareCabins } from 'constants/flight'
import { getFlightDealDuration } from 'lib/flights/flightUtils'
import Caption from 'components/Luxkit/Typography/Caption'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LineExchangeAltIcon from 'components/Luxkit/Icons/line/LineExchangeAltIcon'
import Image from 'components/Common/Image'
import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import { useIntlDateFormatter } from 'lib/datetime/dateUtils'
import { INTL_DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import config from 'constants/config'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import styled from 'styled-components'
import { rem } from 'polished'
import TextLink from 'components/Luxkit/TextLink'
import Clickable from 'components/Common/Clickable'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import { useInView } from 'react-intersection-observer'
import { OFFER_TRACKING_IN_VIEW_THRESHOLD } from 'constants/offerList'

const DealContainer = styled(Group)`
  padding: ${rem(16)};
  border: 1px solid ${props => props.theme.palette.neutral.default.six};
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.palette.neutral.default.six};
  margin-top: ${rem(16)};
`

const ContentContainer = styled(VerticalSpacer)`
  flex: 1;
`

interface Props {
  flightDeal: App.FlightDeal;
  position?: number
}

function FlightDealTile(props: Props) {
  const { flightDeal, position } = props
  const fareTypeLabel = fareTypes.find(type => type.value === flightDeal.fareType)?.label
  const fareClassLabel = fareCabins.find(type => type.value === flightDeal.fareClass)?.label
  const travelDates = useMemo(() => getFlightDealDuration(flightDeal.travelPeriods), [flightDeal.travelPeriods])
  const isOnewayFlight = flightDeal.fareType === 'oneWay'
  const dateFormatter = useIntlDateFormatter()
  const onEvent = useContext(OfferListEventsContext)

  const [inViewRef, hasBeenInView] = useInView({
    triggerOnce: true,
    threshold: OFFER_TRACKING_IN_VIEW_THRESHOLD,
  })

  useEffect(() => {
    if (hasBeenInView && position !== undefined) {
      onEvent(OfferListEvents.impression, {
        offer: flightDeal,
        position,
        key: EventDataKey.FlightImpression,
      })
    }
  }, [hasBeenInView, flightDeal, position, onEvent])

  const onClick = useCallback(() => {
    if (position !== undefined) {
      onEvent(OfferListEvents.productClick, {
        offer: flightDeal,
        position,
        key: EventDataKey.FlightClick,
      })
    }
  }, [position, flightDeal, onEvent])

  return (
    <Clickable
      to={`/flight/deal/${flightDeal.id}`}
      target={config.OPEN_NEW_TAB_OFFER_CLICK ? '_blank' : undefined}
      ref={inViewRef}
      onClick={onClick}
    >
      <DealContainer direction="vertical" gap={12} verticalAlign="space-between" fullHeight>
        <ContentContainer gap={20}>
          <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center" gap={16}>
            <Image
              image={flightDeal.carrier.logo}
              width={100}
              alt={`Logo of ${flightDeal.carrier.name}`}
            />
            <PromotionLabel variant="default" icon={<LineAwardIcon />}>Sale ends {dateFormatter(flightDeal.salesEndDate, INTL_DMY_CASUAL_SHORT_FORMAT)}</PromotionLabel>
          </Group>

          <Group direction="horizontal" horizontalAlign="space-between" gap={16}>
            <Group direction="horizontal" gap={4} verticalAlign="end">
              <VerticalSpacer gap={2}>
                <Caption variant="small" colour="neutral-three" weight="bold" format="uppercase">
                  {flightDeal.originAirportCode}
                </Caption>
                <BodyText variant="large" weight="semi-bold">{flightDeal.originName}</BodyText>
              </VerticalSpacer>
              {isOnewayFlight ? <LineArrowRightIcon /> : <LineExchangeAltIcon />}
              <VerticalSpacer gap={2}>
                <Caption variant="small" colour="neutral-three" weight="bold" format="uppercase">
                  {flightDeal.destinationAirportCode}
                </Caption>
                <BodyText variant="large" weight="semi-bold">{flightDeal.destinationName}</BodyText>
              </VerticalSpacer>
            </Group>
          </Group>

          <VerticalSpacer gap={2}>
            <BodyText variant="small" colour="neutral-three">{isOnewayFlight ? 'One way flight' : 'Return flights'} • {fareClassLabel} </BodyText>
            <BodyText variant="small" colour="neutral-three">
              Travel between {travelDates}
            </BodyText>
          </VerticalSpacer>
        </ContentContainer>

        <Separator />

        <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
          <div>
            <PriceRowPriceCaption>{fareTypeLabel} from</PriceRowPriceCaption>
            <PriceRowPrice
            size="L"
            price={flightDeal.perAdultPrice}
            saleUnit="adult"
          />
          </div>
          <TextLink
            size="caption"
            weight="regular"
          >
            View flights
          </TextLink>
        </Group>
      </DealContainer>
    </Clickable>
  )
}

export default FlightDealTile
