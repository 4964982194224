import React from 'react'
import FlightDeals from './FlightDeals'
import { rem } from 'polished'
import styled from 'styled-components'
import useFlightDeals from 'hooks/Flights/useFlightDeals'
import useFlightDealLocations from 'home/hooks/useFlightDealLocations'

const Container = styled.div`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

function HomepageFlightDeals() {
  const [flightDeals, flightDealsLoading] = useFlightDeals()
  const [locations] = useFlightDealLocations()

  if (!flightDealsLoading && flightDeals.length === 0) {
    return null
  }

  return (
    <Container>
      <FlightDeals title={`Latest flight deals ${locations.length > 1 ? ' from' : ''}`} />
    </Container>
  )
}

export default React.memo(HomepageFlightDeals)
