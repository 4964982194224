import React, { useMemo, useState } from 'react'
import FlightDealTile from 'components/Flights/FlightMerchandising/FlightDealTile'
import Heading from 'components/Luxkit/Typography/Heading'
import useFlightDealLocations from 'home/hooks/useFlightDealLocations'
import LayoutContainer from 'components/Common/LayoutContainer'
import { MAX_CAROUSEL_ITEM_COUNT } from '../OfferCarousels/common/constants'
import { sortBy, take } from 'lib/array/arrayUtils'
import FullWidthCarouselWithAnalytics from 'components/WithAnalytics/Carousel/FullWidthCarouselWithAnalytics'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FlightDealAirportSelect from './FlightDealAirportSelect'
import { rem } from 'polished'
import useFlightDeals from 'hooks/Flights/useFlightDeals'
import Group from 'components/utils/Group'
import FlightDealTileSkeletonLoader from './FlightDealSkeletonLoader'

interface Props {
  title: string;
  category?: string;
}

function FlightDeals(props: Props) {
  const { title, category } = props
  const [flightDeals, fetching] = useFlightDeals(category)
  const [airports, defaultAirport] = useFlightDealLocations(category)
  const [selectedAirport, setAirport] = useState<App.Airport | undefined>()

  const airport = selectedAirport ?? defaultAirport
  const filteredFlightDeals = useMemo(() => {
    if (airport) {
      const deals = flightDeals.filter(({ originAirportCode }) => originAirportCode === airport.code)
      return sortBy(deals, (deal) => deal.hierarchy, 'asc')
    }
    return []
  }, [airport, flightDeals])

  return (
    <VerticalSpacer gap={20}>
      <LayoutContainer>
        <Group direction="vertical" tabletDirection="horizontal" tabletGap={8}>
          <Heading variant="heading2">{title}</Heading>
          {airport && airports.length > 1 &&
            <FlightDealAirportSelect
              selected={airport}
              airports={airports}
              onChange={setAirport}
            />
          }
        </Group>
      </LayoutContainer>
      <FullWidthCarouselWithAnalytics
        width={`calc(100vw - ${rem(56)})`}
        snap="start"
        tabletWidth={rem(366)}
        itemsPerArrow={3}
      >
        {fetching && <>
          <FlightDealTileSkeletonLoader />
          <FlightDealTileSkeletonLoader />
          <FlightDealTileSkeletonLoader />
          <FlightDealTileSkeletonLoader />
        </>}
        {!fetching && take(filteredFlightDeals, MAX_CAROUSEL_ITEM_COUNT).map((flightDeal, index) => (
          <FlightDealTile position={index} key={flightDeal.id} flightDeal={flightDeal}/>
        ))}
      </FullWidthCarouselWithAnalytics>
    </VerticalSpacer>
  )
}

export default FlightDeals
